@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  height: 100vh;
}

.navigation-btn button {
  padding: .3em .6em;
  border: 1px solid #e4e5f7;
  margin-right: 3px;
  display: inline-block;
  min-width: 1.5em;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  border-radius: 4px;
}

.navigation-btn select{
  border: 1px solid #e4e5f7;
  margin-left: 4px;
}

.pagination-top{
  margin-bottom: .5rem;
}

.pagination-top select{
  border: 1px solid #e4e8ea;
    color: #76818a;
    background: transparent;
}

.pagination-top input{
  box-shadow: none !important;
  border: 1px solid #c1c7cd;
  color: #646464;
  border-radius: 4px;
  padding: 5px 12px;
  height: 32px;
  background: transparent;
}

.table thead th{
  border-bottom: 1px solid #ddd;
}

.btn-close{
  display: none;
}

.modal-header{
  display: ruby block;
}

.action_btn_ic {
  background: transparent;
  border: none;
  font-size: 20px;
}

.page-container:not(.container) {
  width: 100%;
  height: 100vh;
}

.page-inner{
  height: calc(100vh - 106px); 
}

.page-sidebar{
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 100;
  height: 100% !important;
}

.page-content{
  margin-left:260px;
}

.page-sidebar-collapsed .page-content{
  margin-left:60px;
}

.accordion-menu li a i{
  display: none;
}

.page-sidebar-collapsed .page-sidebar .accordion-menu li a i{
  display: block;
  font-size: 25px;
  text-align: center;
}

@media (max-width:991px){
  .page-sidebar{
    left: -264px;
  }

  .page-sidebar-collapsed .page-sidebar .accordion-menu li a i{
    display: none;
  }

  .page-sidebar-collapsed .page-sidebar{
    width: 250px;
    left: 0;
  }

  .page-sidebar-collapsed .page-sidebar .logo {
    width: 250px;
}

.page-sidebar-collapsed .page-sidebar-menu ul li a span, .page-sidebar-collapsed .page-sidebar-menu ul li a i.accordion-icon {
  display: block;
}

.page-sidebar-collapsed .page-sidebar-menu>ul>li>a, .page-sidebar-collapsed .page-sidebar-menu>ul>li {
  width: auto;
}

.page-sidebar-collapsed #sidebar-toggle-button-close {
  margin:0 0 0 auto;
}

  .page-content{
    margin-left:0;
  }

  .custome_pointer{
     cursor: pointer !important
  }
}